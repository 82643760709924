import React, { useState, useEffect } from "react"
import Layout from "../components/Layout"
import { ProfesorContainer, Profesor, Container } from "../styles/NosotrosStyle"
import { Title } from "../styles/Common"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

import Onda from "../assets/icons/ondasup.svg"
import BolaNaranja from "../assets/icons/bolanaranja.svg"

const Nosotros = () => {
  const [data, setData] = useState(
    useStaticQuery(graphql`
      {
        allFile(filter: { sourceInstanceName: { eq: "profes" } }) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 300, quality: 50) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `)
  )

  return (
    <>
      <Layout>
        <Container>
          <Title>
            <h2>Conoce a nuestros profesores, todos nativos y cualificados</h2>
            <h3>
              Todos nuestros profesores, además de ser nativos, están
              cualificados y tienen experiencia en la enseñanza de Inglés como
              idioma extranjero.
            </h3>
          </Title>
          <ProfesorContainer>
            <Onda className="onda"></Onda>
            <BolaNaranja className="bolanaranja"></BolaNaranja>
            <Profesor>
              <div className="circle">
                <Image
                  fluid={data.allFile.edges[3].node.childImageSharp.fluid}
                ></Image>
              </div>
              <div className="info">
                <p className="name">Judith Boswoth</p>
                <p className="from">Británica</p>
                <p className="speciality">Especialidad niñ@s de 5 a 12 años</p>
              </div>
            </Profesor>
            <Profesor>
              <div className="circle">
                <Image
                  fluid={data.allFile.edges[0].node.childImageSharp.fluid}
                ></Image>
              </div>
              <div className="info">
                <p className="name">Valerie McMillan</p>
                <p className="from">Irlandesa</p>
                <p className="speciality">
                  Especialidad niñ@s de 5 a 12 años
                </p>
              </div>
            </Profesor>
            <Profesor>
              <div className="circle">
                <Image
                  fluid={data.allFile.edges[2].node.childImageSharp.fluid}
                ></Image>
              </div>
              <div className="info">
                <p className="name">Ennea Davis</p>
                <p className="from">Británica</p>
                <p className="speciality">Especialidad niñ@s de 5 a 12 años</p>
              </div>
            </Profesor>
            <Profesor>
              <div className="circle">
                <Image
                  fluid={data.allFile.edges[1].node.childImageSharp.fluid}
                ></Image>
              </div>
              <div className="info">
                <p className="name">Janine Davis</p>
                <p className="from">Británica</p>
                <p className="speciality">
                  Gerente y profesora del Centro. Especializada en la
                  preparación de exámenes oficiales de Cambridge
                </p>
              </div>
            </Profesor>
          </ProfesorContainer>
        </Container>
      </Layout>
    </>
  )
}

export default Nosotros
