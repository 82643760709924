import styled, { css } from "styled-components"

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;
  padding-left: 5rem;
  padding-right: 5rem;
  min-height: calc(100vh - 120px);

  /* background-color: pink; */
`

export const ProfesorContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  /* background-color: red; */
  flex-wrap: wrap;

  .onda {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 40rem;
    z-index: -20;
  }

  .bolanaranja {
    position: absolute;
    left: -20px;
    width: 40%;
    z-index: -20;
    top: 15rem;
    transform: scaleX(-1);
    opacity: 0.5;
    @media (max-width: 890px) {
      display: none;
    }
  }
`
export const Profesor = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  /* background-color: white; */
  height: fit-content;
  width: 16rem;
  margin: 0.7rem;
  padding-top: 2rem;

  div.circle {
    border-radius: 100%;
    height: 13rem;
    width: 13rem;
    background-color: pink;
    margin-top: 0px;
    margin-bottom: 1.2rem;
    border: 3px solid #ff6f5e;
    overflow: hidden;

    div.gatsby-image-wrapper {
      height: 100%;
      width: 100%;
    }
  }

  div.info {
    color: #2c3d63;
    p.name {
      margin: 0px;
      text-align: center;
      margin-bottom: 0.5rem;
      font-family: "psemibold";
      font-size: 19px;
    }

    p.from {
      margin: 0px;
      text-align: center;
      margin-bottom: 0.5rem;
      font-family: "pregular";
      font-size: 14px;
    }

    p.speciality {
      margin: 0px;
      text-align: center;
      margin-bottom: 0.5rem;
      font-family: "plight";
      font-size: 12px;
    }
  }
`
